import React, { Component } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import slugify from "react-slugify";
import Img from "gatsby-image";

const dictionary = require("../dic/dic.json");

class IntegrationPage extends Component {
  componentDidMount () {
    const lang = this.props.pageContext.idioma;
    // console.log("CATEGORY", this.props.pageContext.idCategory)
    const a = document.createElement("a");

    a.setAttribute("href", "#all");

    a.textContent = dictionary[lang].all;
    a.classList.add("active");
    const newItem = document.createElement("li");
    newItem.classList.add("menu-item");

    const list = document.getElementById("menu-integraciones");
    newItem.appendChild(a);
    if (list !== null) {
      list.appendChild(newItem);
      list.insertBefore(newItem, list.childNodes[0]);

      const idTab = document.location.hash;
      const categoriasIntegracions = this.props.data.wpCategory.wpChildren.nodes;
      // console.log("CATEGORIES", categoriasIntegracions)
      const allIntegraciones = document.querySelectorAll(
        ".av-alb-blogposts .post-entry"
      );
      // console.log(allIntegraciones);
      for (var j = 0; j < allIntegraciones.length; j++) {
        allIntegraciones[j].classList.add("d-none");
        for (const x in categoriasIntegracions) {
          const integracions = categoriasIntegracions[x].posts.nodes;
          for (var i in integracions) {
            const post = integracions[i];
            if (
              allIntegraciones[j].classList.contains(
                "post-entry-" + post.databaseId
              )
            ) {
              // console.log("entra");
              if (
                post.integraciones_proximamente !== null &&
                post.integraciones_proximamente.proximamenteIntegraciones ===
                "Si"
              ) {
                allIntegraciones[j].classList.add("proximamente");
              }
              allIntegraciones[j].classList.remove("d-none");
            }
          }
        }
      }
      const categories_integration = document.querySelectorAll(
        "#menu-integraciones li > a"
      );
      categories_integration[0].addEventListener("click", this.showCategories);
      for (j = 1; j < categories_integration.length; j++) {
        categories_integration[j].href =
          "#" + slugify(categories_integration[j].innerHTML);
        categories_integration[j].addEventListener("click", this.showCategories);
        for (i in categoriasIntegracions) {
          const integracio = categoriasIntegracions.find(
            cat => cat.name === categories_integration[j].innerHTML
          );

          if (integracio !== null && integracio !== undefined) {
            categories_integration[j].dataset.posts = JSON.stringify(
              integracio.posts.nodes
            );
            break;
          }
        }
      }
      if (idTab !== "" && idTab !== "#all") {
        for (j = 0; j < categories_integration.length; j++) {
          categories_integration[j].classList.remove("active");
        }
        const element = document.querySelector(
          ".menu-item a[href='" + idTab + "']"
        );
        element.classList.add("active");
        const posts = JSON.parse(element.dataset.posts);
        const allPosts = document.querySelectorAll(
          ".av-alb-blogposts .post-entry"
        );
        for (j = 0; j < allPosts.length; j++) {
          allPosts[j].classList.add("d-none");
          for (i in posts) {
            const post = posts[i];
            if (
              allPosts[j].classList.contains("post-entry-" + post.databaseId)
            ) {
              allPosts[j].classList.remove("d-none");
            }
          }
        }
      }
    }
  }

  showCategories = event => {
    const categories_integration = document.querySelectorAll(
      "#menu-integraciones li > a"
    );
    for (var i = 0; i < categories_integration.length; i++) {
      categories_integration[i].classList.remove("active");
    }
    const element = event.target;
    element.classList.add("active");
    let posts = null;
    if (element.dataset.posts !== undefined) {
      posts = JSON.parse(element.dataset.posts);
    }
    const allPosts = document.querySelectorAll(".av-alb-blogposts .post-entry");
    for (let j = 0; j < allPosts.length; j++) {
      if (posts !== null) {
        allPosts[j].classList.add("d-none");

        for (i in posts) {
          const post = posts[i];
          if (allPosts[j].classList.contains("post-entry-" + post.databaseId)) {
            allPosts[j].classList.remove("d-none");
          }
        }
      } else {
        allPosts[j].classList.remove("d-none");
      }
    }
  };

  // Crear query en template page per integraciones (recuperar tots els posts de la categoria i passar-ho per propietats)
  // Una vegada s'hagi carregat el VDOM afegir els posts de cada categoria com a attribut i desde el jquery o aquí mateix crear la funcionalitat de tags
  render () {
    const page = this.props.data.wpPage;
    const idPage = page.databaseId;
    const app_json = page.aplication_json_ld_reviews_gql.aplicationJsonLdReviews;
    const integrationsCategories = this.props.data.wpCategory.wpChildren.nodes;
    const slug_cat = this.props.data.wpCategory.slug;
    const postsIntegration = this.props.data.allWpPost.edges;
    const seo = page.seo;
    let slugPage = seo.canonical.replace("https://www.getbillage.com/wp", "");
    slugPage = slugPage.replace("https://getbillage.com/wp", "");
    let openGraphslugPage = seo.opengraphUrl.replace(
      "https://www.getbillage.com/wp",
      ""
    );
    openGraphslugPage = openGraphslugPage.replace(
      "https://getbillage.com/wp",
      ""
    );
    const langPage = this.props.pageContext.idioma;
    const alternates = page.translated;

    const developers = this.props.data.wpPage.developers;
    // console.log("INTEGRATION-PAGE", this.props);
    return (
      <Layout
        currentPage={page.title}
        langPage={this.props.pageContext.idioma}
        idPage={this.props.data.wpPage.id}
        id_pagina={developers !== null ? developers.idPagina : ""}
        clase_pagina={developers !== null ? developers.clasePagina : ""}
        alternates={alternates}
      >
        <SEO
          title={seo.title}
          description={seo.metaDesc}
          robotsNofollow={seo.metaRobotsNofollow}
          robotsNoindex={seo.metaRobotsNoindex}
          canonical={slugPage}
          openGraphSite={seo.opengraphSiteName}
          openGraphType={seo.opengraphType}
          openGraphUrl={openGraphslugPage}
          openGraphImg={
            seo.opengraphImage !== null
              ? seo.opengraphImage.sourceUrl
              : "/images/logo-billage-default-social-share.jpg"
          }
          openGraphImgWidth={
            seo.opengraphImage !== null
              ? seo.opengraphImage.mediaDetails.width
              : 300
          }
          openGraphImgHeight={
            seo.opengraphImage !== null
              ? seo.opengraphImage.mediaDetails.height
              : 300
          }
          dctermsCreated={page.date}
          dctermsModified={page.modified}
          lang={langPage}
          twitterImg={
            seo.twitterImage !== null
              ? seo.twitterImage.sourceUrl
              : "/images/logo-billage-default-social-share.jpg"
          }
          twitterImgHeight={
            seo.twitterImage !== null
              ? seo.twitterImage.mediaDetails.height
              : 300
          }
          twitterImgWidth={
            seo.twitterImage !== null
              ? seo.twitterImage.mediaDetails.width
              : 300
          }
          alternates={alternates}
          appJson={app_json}
          idPage={idPage}
        />
        <div className="integraciones-page row">
          <div
            dangerouslySetInnerHTML={{
              __html: this.props.data.wpPage.page_content.contentRaw
            }}
          />
          <div
            id="bloque-integraciones"
            className="avia-section main_color avia-section-default avia-no-border-styling  avia-bg-style-scroll  avia-builder-el-3  el_after_av_section  avia-builder-el-last  row-eq-height  container_wrap sidebar_right"
          >
            <div className="container">
              <div className="template-page content  av-content-small alpha units">
                <div className="post-entry post-entry-type-page post-entry-4813">
                  <div className="entry-content-wrapper clearfix">
                    <div className="flex_column col-3  flex_column_div av-zero-column-padding first  avia-builder-el-4  el_before_col-9  avia-builder-el-first  brc categorias ">
                      <div className="avia-builder-widget-area clearfix  avia-builder-el-5  avia-builder-el-no-sibling ">
                        <div
                          id="nav_menu-2"
                          className="widget clearfix widget_nav_menu"
                        >
                          <h3 className="widgettitle">
                            {dictionary[langPage].categories}
                          </h3>
                          <div className="menu-integraciones-container">
                            <ul id="menu-integraciones" className="menu">
                              {integrationsCategories.map(category => {
                                return (
                                  <li key={"menu-item-" + category.databaseId}
                                    id={"menu-item-" + category.databaseId}
                                    className={
                                      "menu-item menu-item-type-taxonomy menu-item-object-category menu-item-" +
                                      category.databaseId
                                    }
                                  >
                                    <a href={"#" + category.slug}>
                                      {category.name}
                                    </a>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex_column col-9  flex_column_div av-zero-column-padding   avia-builder-el-6  el_after_col-3  avia-builder-el-last  grid listado-servicios ">
                      <div
                        className="av-alb-blogposts template-blog  "
                        itemScope="itemscope"
                        itemType="https://schema.org/Blog"
                      >
                        {postsIntegration.map(node => {
                          const post = node.node;
                          const proxAux =
                            post.integraciones_proximamente
                              .proximamenteIntegraciones;
                          let proximamente = "";
                          if (proxAux === "Si") {
                            proximamente = "proximamente";
                          }
                          // console.log(post)
                          return (
                            <article key={"post_" + post.databaseId}
                              className={`post-entry post-entry-type-standard post-entry-${post.databaseId} post-loop-1 post-parity-odd multi-big with-slider post-${post.databaseId} post type-post status-publish format-standard has-post-thumbnail hentry category-integraciones ${proximamente}`}
                              itemScope="itemscope"
                              itemType="https://schema.org/BlogPosting"
                            >
                              <div className="int-article">
                                <a
                                  className="cardlink"
                                  href={`/${langPage}/${slug_cat}/${post.slug}`}
                                >
                                  <div className="big-preview multi-big">
                                    {post.featuredImage !== null &&
                                      post.featuredImage.node !== null &&
                                      post.featuredImage.node.localFile !=
                                      null
                                      ? (
                                      <Img
                                        fluid={
                                          post.featuredImage.node.localFile
                                            .childImageSharp.fluid
                                        }
                                      />
                                        )
                                      : null}
                                  </div>
                                  <header className="entry-content-header">
                                    <h2
                                      className="post-title entry-title "
                                      itemProp="headline"
                                    >
                                      <a
                                        href={`/${langPage}/${slug_cat}/${post.slug}`}
                                        rel="bookmark"
                                        title={post.title}
                                      >
                                        {post.title}
                                        <span className="post-format-icon minor-meta"></span>{" "}
                                      </a>
                                    </h2>
                                  </header>
                                  <div className="entry-content-wrapper clearfix standard-content">
                                    <div
                                      className="entry-content"
                                      itemProp="text"
                                      dangerouslySetInnerHTML={{
                                        __html: post.excerpt
                                      }}
                                    />
                                    <footer className="entry-footer"></footer>
                                    <div className="post_delimiter"></div>
                                  </div>
                                </a>
                              </div>
                            </article>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default IntegrationPage;

export const integrationQuery = graphql`
  query integrationQuery($id: String, $idCategory: Int!) {
    wpPage(id: { eq: $id }) {
      title
      content
      slug
      id
      date
      databaseId
      page_content {
        contentRaw
      }
      developers {
        idPagina
        clasePagina
      }
      aplication_json_ld_reviews_gql {
        aplicationJsonLdReviews
      }
      uri
      modified
      locale {
        id
      }
      seo {
        canonical
        title
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        opengraphPublisher
        opengraphImage {
          altText
          sourceUrl
          title
          mediaDetails {
            height
            width
          }
        }
        twitterDescription
        twitterTitle
        schema {
          pageType
        }
        twitterImage {
          altText
          mediaDetails {
            height
            width
          }
          sourceUrl
        }
      }
      author {
        node {
          name
        }
      }
      translated {
        locale{
          locale
        }
        seo {
          canonical
        }
      }
    }
    wpCategory(databaseId: { eq: $idCategory }) {
      slug
      wpChildren {
        nodes {
          databaseId
          name
          posts {
            nodes {
              databaseId
              integraciones_proximamente {
                proximamenteIntegraciones
              }
              title
              slug
            }
          }
          link
          slug
        }
      }
    }
    allWpPost(
      filter: {
        categories: {
          nodes: { elemMatch: { databaseId: { eq: $idCategory } } }
        }
      }
      sort: { order: ASC, fields: title }
    ) {
      edges {
        node {
          databaseId
          integraciones_proximamente {
            proximamenteIntegraciones
          }
          title
          slug
          excerpt
          featuredImage {
            node {
              srcSet
              sourceUrl
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
